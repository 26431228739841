import React, { useEffect, useState } from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AdminService from '../../services/admin-service';
import { SercomTypes } from '../../support/sercom-types';
import SercomHelper from '../../support/sercom-helper';

const DashboardPage = () => {
  const [kpisData, setKpisData] = useState<SercomTypes.KpisData | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    async function init() {
      await refreshKpi();
    }
    init();
  }, []);

  const refreshKpi = async () => {
    const kpisData = await AdminService.getKpis();
    if (kpisData.isSuccess && kpisData.data.isSuccess) {
      setKpisData(kpisData.data.data);
    }
  };

  const getKpiData = (title: string, current: number, target: number) => {
    const ration = current / target;

    return (
      <Paper elevation={2} sx={{ minWidth: 200, height: 120, backgroundColor: '#FFFFFF', borderRadius: 3, px: 2, py: 2, mb: 0.2, ml: 0.2 }}>
        <Stack direction="column" spacing={0} sx={{ alignItems: 'center' }}>
          <Typography variant="h5" sx={{ alignItems: 'center' }}>
            {t(title)}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ alignItems: 'center', color: ration < 0.5 ? 'red' : ration < 0.75 ? 'orange' : 'green', fontSize: 30 }}
          >
            {SercomHelper.getFormattedNumber(current)}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" sx={{ alignItems: 'center', fontSize: 16 }}>
              {`${SercomHelper.getFormattedNumber(target)} (${Math.round((current / target) * 100)}%)`}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    );
  };

  const getKpisData = () => {
    return (
      kpisData && (
        <Stack direction="row" spacing={2} sx={{ alignSelf: 'end', pb: 3 }}>
          {getKpiData('Client SMS count', kpisData.clientSmsCount, 100)}
          {getKpiData('Client Emails count', kpisData.clientEmailCount, 100)}
          {getKpiData('Employee SMS count', kpisData.employeeSmsCount, 100)}
          {getKpiData('Employee Emails count', kpisData.employeeEmailCount, 100)}
        </Stack>
      )
    );
  };

  return (
    <Box sx={{ borderRadius: 2, p: 5, display: 'flex', flexDirection: 'column', background: '#ffffff' }}>
      <Typography variant="h3" sx={{ mb: 3 }}>
        {t('Dashboard')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>{getKpisData()}</Box>
    </Box>
  );
};

export default DashboardPage;

