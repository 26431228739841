import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import {
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  AppBarProps as MuiAppBarProps,
  useMediaQuery,
  Toolbar,
  Box,
  IconButton,
  Stack,
  Menu,
  List,
  Divider,
  Paper,
  Chip,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Logout,
  Add,
  ContactSupportOutlined,
  AccountCircle,
  CreditCardOutlined,
  BusinessOutlined,
  GroupOutlined,
  DashboardOutlined,
} from '@mui/icons-material';
import SercomDrawerItem from '../../components/SercomDrawerItem';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
// import { BrowserView, MobileView } from 'react-device-detect';

import { metadataActions } from '../../store/metadata/metadata-actions';
import SercomEnums from '../../support/sercom-enums';
import SercomMenuItem from '../../components/SercomMenuItem';
import { StorageService } from '../../services';
import SubscriptionPopup from '../subscription/SubscriptionPopup';
import { usePopupManager } from 'react-popup-manager';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface AppBarProps extends MuiAppBarProps {
  isOpen?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, isOpen }) => ({
  zIndex: theme.zIndex.drawer + 1,
  ...{
    marginLeft: isOpen ? drawerWidth : 0,
    width: `calc(100% - ${isOpen ? drawerWidth : 0}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

type MainLayoutProps = {
  logout: (successCallback: Function) => void;
  setDisplayMode: (displayMode: SercomEnums.DisplayMode, successCallback?: Function) => void;
};

const MainLayout = (props: MainLayoutProps) => {
  const { logout } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(true);
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const name = StorageService.getCurrentName();
  const popupManager = usePopupManager();

  const handleOpenDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    logout(() => {
      navigate('/login');
    });
  };

  const onCreateRequest = () => {
    onActionsMenuClose();
    navigate('/request/');
  };

  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(null);
  const [profileSettingsAnchorEl, setProfileSettingsAnchorEl] = React.useState<null | HTMLElement>(null);
  const openActionsMenu = Boolean(actionsAnchorEl);
  const openProfileSettingsMenu = Boolean(profileSettingsAnchorEl);

  const openSubscriptionPopup = () => {
    popupManager.open(SubscriptionPopup, {
      action: () => {},
      buttonText: t('Close'),
      onClose: () => {},
    });
  };

  const onActionsMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionsAnchorEl(event.currentTarget);
  };
  const onActionsMenuClose = () => {
    setActionsAnchorEl(null);
  };
  const onProfileSettingsMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setProfileSettingsAnchorEl(event.currentTarget);
  };
  const onProfileSettingsMenuClose = () => {
    setProfileSettingsAnchorEl(null);
  };

  useEffect(() => {
    setIsOpen(matchUpMd);
    async function init() {}
    init();
  }, []);

  const drawer = (
    <PerfectScrollbar
      component="div"
      style={{
        height: 'calc(100vh - 56px)', // !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
        paddingLeft: '16px',
        paddingRight: '16px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box>
        <List>
          <SercomDrawerItem
            title={t('Compaies')}
            icon={<BusinessOutlined />}
            isOpen={isOpen}
            callbackAction={() => navigate('/companies')}
          />
        </List>
        <List>
          <SercomDrawerItem
            title={t('Dashboard')}
            icon={<DashboardOutlined />}
            isOpen={isOpen}
            callbackAction={() => navigate('/dashboard')}
          />
        </List>
        <List>
          <SercomDrawerItem title={t('Leads')} icon={<GroupOutlined />} isOpen={isOpen} callbackAction={() => navigate('/leads')} />
        </List>
        <Box mb={3}>
          <Divider />
        </Box>
      </Box>
    </PerfectScrollbar>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: isOpen ? theme.transitions.create('width') : 'none',
          zIndex: 1000,
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {isOpen && (
              <Box sx={{ width: drawerWidth - 20, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                <img src="/logo305.png" alt="Sercom" width="140px" />
              </Box>
            )}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleOpenDrawer}
              edge="start"
              sx={{
                // width: 42,
                marginRight: 3,
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Stack direction="row" spacing={2}>
            <IconButton className="sc-main-button" onClick={onActionsMenuOpen}>
              <Add />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={actionsAnchorEl}
              open={openActionsMenu}
              onClose={onActionsMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <SercomMenuItem title={t('CreateRequest')} icon={<ContactSupportOutlined />} callbackAction={onCreateRequest} />
            </Menu>
            <Chip
              color="default"
              clickable={true}
              icon={<AccountCircle />}
              label={name}
              sx={{ height: '40px', borderRadius: '20px', fontSize: '10pt', fontWeight: 'bold', color: '#495579' }}
              onClick={onProfileSettingsMenuOpen}
            />
            <Menu
              id="profile-menu"
              anchorEl={profileSettingsAnchorEl}
              open={openProfileSettingsMenu}
              onClose={onProfileSettingsMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <SercomMenuItem title={t('Subscription')} icon={<CreditCardOutlined />} callbackAction={openSubscriptionPopup} />
              <SercomMenuItem title={t('Logout')} icon={<Logout />} callbackAction={handleLogout} />
            </Menu>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={isOpen}
        anchor="left"
        sx={{
          '& .MuiDrawer-paper': {
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            zIndex: 1000,
            borderRight: 'none',
            top: 88,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Box component="main">
        <Paper sx={{ overflow: 'auto', height: 'calc(100vh - 100px)', backgroundColor: '#ffffff' }}>
          <Outlet />
        </Paper>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      logout: metadataActions.logout,
      setDisplayMode: metadataActions.setDisplayMode,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(MainLayout);

