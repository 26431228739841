import React, { useCallback } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import LoginPage from '../pages/authentication/Login';
import HomePage from '../pages/home/Home';
import MainLayout from '../pages/shared/MainLayout';
import { v4 as uuid } from 'uuid';
import SercomEnums from './sercom-enums';
import { useTranslation } from 'react-i18next';
import CompaniesPage from '../pages/company/Companies';
import LeadsPage from '../pages/leads/Leads';
import DashboardPage from '../pages/dashboard/Dashboard';

type SercomRoutesProps = {
  isLogged: boolean;
  companyId: number | null;
  permission: number;
  language: number;
};

const LoggedInLayout = () => {
  return (
    <>
      <MainLayout />
    </>
  );
};

const SercomRoutes = (props: SercomRoutesProps) => {
  const { isLogged, permission, language } = props;
  const location = useLocation();
  const locationState = location.state as { from?: { pathname: string } } | null;
  const fromPathname = locationState?.from?.pathname;
  const { i18n } = useTranslation();

  const protectRouteElement = useCallback(
    (element: JSX.Element) => {
      const strLanguage = language == SercomEnums.Language.French ? 'fr' : 'en';
      if (i18n.language != strLanguage) {
        i18n.changeLanguage(strLanguage);
      }

      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      if (isLogged) {
        // const validator = locationValidators[location.pathname];
        // if (!validator || validator({ canManageAdminUsers, canManageNewFeature, canManageTaskListRule, canCreateAccount })) {
        //   return element;
        // }
        return element;
      }
      return <Navigate to="/login" state={{ from: location }} />;
    },
    [isLogged, location, permission, language]
  );

  return (
    <Routes>
      <Route path="login" element={isLogged ? <Navigate to={fromPathname ?? '/companies'} replace /> : <LoginPage />} />
      <Route element={protectRouteElement(<LoggedInLayout />)}>
        <Route path="/home" element={<HomePage key={uuid()} />} />
        <Route path="/companies" element={<CompaniesPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/leads" element={<LeadsPage />} />
      </Route>
      <Route path="*" element={<Navigate to={isLogged ? '/companies' : '/login'} />} />
    </Routes>
  );
};

export default SercomRoutes;

